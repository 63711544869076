
/* GENERAL SETTINGS START */
$primaryColor: #fe5b29;
$secondaryColor: #111111;
$tertiaryColor: #2b3036;
$quaternaryColor: #cf4419;
$quinaryColor: #fcf8f8;

.primary-color { color: $primaryColor; }
.secondary-color { color: $secondaryColor; }
.tertiary-color { color: $tertiaryColor; }
.quaternary-color { color: $quaternaryColor; }
.quinary-color { color: $quinaryColor; }

.primary-bg-color { background-color: $primaryColor;  }
.secondary-bg-color { background-color: $secondaryColor; }
.tertiary-bg-color { color: $tertiaryColor; }
.quaternary-bg-color { color: $quaternaryColor; }
.quinary-bg-color { color: $quinaryColor; }

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    font-family: 'Open Sans', sans-serif;
}

.fw-400 { font-weight: 400; }
.fw-500 { font-weight: 500; }
.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700; }
.fw-800 { font-weight: 800; }

svg polyline {
    stroke: $quinaryColor;
}
/* GENERAL SETTINGS END */

/* MOBILE SETTINGS START */
@media only screen and (min-width: 768px) {
    #slider .banner-section {
        min-height: 500px;
    }
}
/* MOBILE SETTINGS END */


/* HEADER START */
header#header > .header-line-2 {
    background-color: $primaryColor;
}
header#header > .header-line-2 .header-line-2-icon {
    margin-top:-3px;
}
/* HEADER END */

/* SLIDER START */
.carousel, .carousel-caption, .carousel-inner {
    position: static;
}
.carousel-indicators, .carousel-control-prev, .carousel-control-next {
    display: none;
}

#slider .next-button:hover {
    background-color: $tertiaryColor;
}
#slider .prev-button:hover {
    background-color: $quaternaryColor;
}

#slider .banner-section {
    background-image: url("assets/images/banner-bg.jpg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: contain;
}
/* SLIDER END */

/* ABOUT SECTION START */
#about-section .readmore-btn {
    background-color: $primaryColor;
    border-radius: 3px;
    color: $quinaryColor;
    text-align: center;
    text-transform: uppercase;
}
#about-section .readmore-btn:hover {
    background-color: $tertiaryColor;
}
/* ABOUT SECTION END */


/* CAR SEARCH START */
#car-search {
    background-color:$tertiaryColor;
}
#car-search .search-btn {
    background-color: $primaryColor;
    color: $quinaryColor;
    text-transform: uppercase;
    border:0;
}
#car-search a:not([disabled]) .search-btn:hover {
    color: $tertiaryColor;
    background-color: $quinaryColor;
}
/* CAR SEARCH END */


/* CAR OFFERS START */
#car-offers .gallery-box {
    box-shadow: 0px 0px 6px 6px #f4f3f2;
}
#car-offers .rent-now-button:hover {
    color: $tertiaryColor;
    background-color: $quinaryColor;
}
/* CAR OFFERS END */


/* FEATURES SECTION START */
#features-section {
    background-color:$tertiaryColor;
}
/* FEATURES SECTION END */


/* CONTACT SECTION START */
/* CONTACT SECTION END */


/* FOOTER START */
#footer .social-icon {

}
#footer .social-icon ul li {
    float:left;
    margin: 3px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
}

#footer .social-icon ul li a {
    font-size:20px;
}
#footer .social-icon ul li a svg {
    margin-top:-3px;
}
/* FOOTER END */

/* MY RENTALS START */
#my-rentals {
    clear:both;
}
/* MY RENTALS END */


.react-select .select__control {
    border-radius: 0 5px 5px 0;
}
.recaptcha > div > div {
    margin: auto;
}

header.admin-header .navbar-expand-lg .navbar-nav .nav-link {
    color: $tertiaryColor;
}
header.admin-header .dropdown-item.active, .dropdown-item:active {
    color: $tertiaryColor;
    background-color: #ffffff;
}